import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/Windows/NavButtons';
import ForumBox from 'components/Software/Windows/iSpy/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/iSpy/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app iSpy for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_iSpy_Connect.png",
  "social": "/images/Search/P_SearchThumb_iSpy_Connect.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-iSpy_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='iSpy' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app iSpy for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_iSpy_Connect.png' twitter='/images/Search/P_SearchThumb_iSpy_Connect.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/iSpy/' locationFR='/fr/Software/Windows/iSpy/' crumbLabel="iSpy Connect" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "ispy-connect",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ispy-connect",
        "aria-label": "ispy connect permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iSpy Connect`}</h3>
    <p>{`iSpy uses your cameras, web cams, IP cams and microphones to detect and record movement or sound. Captured media is compressed to flash video or mp4 and streamed securely over the web and local network. iSpy can run on multiple computers simultaneously and has full Email, SMS and Twitter alerting functions and remote viewing. Some key features are: Unlimited Cameras and Microphones (including IP and USB cameras), Motion Detection (3 types), Motion Processing (4 types), Record on Detection, Record on Schedule, Record with Audio, Remote Access, Network Audio Broadcasting (via iSpy Server), Password Protection, Desktop Recording, YouTube Uploading, SMS, Twitter and Email alerts.`}</p>
    <p>{`In order to integrate your INSTAR IP camera in `}<a href="https://www.ispyconnect.com/" target="_blank" rel="noopener noreferrer">{`iSpy from iSpy Connect`}</a>{` we recommend you to download the latest version from `}<a href="https://www.ispyconnect.com/download.aspx" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fdd9772e2302fbd5571f525932f63f17/71ee9/ISpy_mainview.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADUElEQVQ4y32S3U+bVRzHn3jjf+CNF3qhMyaLwfgSHdBSGHYwBEFX7NMHSlvbUto8RbSOKqWj4JrgBXHJ6Bu0CU4HdIOBYxq8IJv4MmGgsBZXZcCuTHRxiRcw2voxfTYWXxZP8sk5ycn5/H6/k6+wsbnJbzdv8vutW2xv75DN5chms+RyOXZ2s2SzOW7v7t5je+c22Vxeucvl8+Tzf7KbzVJYqVQaYX4pzfLaJvNXUiz8sM78Ypq5b6+ykN7iu9Q6K5kbfL+2wXJ6g6X0Ot8spVi8+jNfLq6yem2L1cwWKz9uktn6hZkvLiIEg320O0WC/g4mpoJcnutlYTbC2ImjvGkXMetraayv4rWGl9FqVLhaWohEY4QGQwze5eTJQaLRGD09AQS/7x10h58g0CXTfyLA9EyYP369xMXhdiY+6iaznMDX0ciTjz3Kgw8IaNQlJBIJwuEQkUhEIRwOMzw8TF9fH0KP34v4ynN0+by80WajqaWKWDzK7Eg3F85HWN7IcGp6FtlzjBLVi2grNcTjcUVyX+Exvxdd3Qu43DIWaxPV2oeRHXV4mw8jy06CH0Z4L9BP+1EfosmE0dTMUCymSPake8Le3l4Ev9/Lq3UlNJtMSMYmKjSPUFO7D7F8P0ZdNR6nno6338LR7uGIQcLZ7ib2v8JuLw21KvRSI5LZRbn6KYqefojiZx+n9pAGUVeLvc2FxeGmvlFPq8v5D+Eef+vwXWpqVNQ3NtBgsHDwUDXPFO3n+aJ9aA+qqCgrobxczRGxieq6eqytdkUYCoX+06Hyhz5fFy9pK9FWVVGqVlNRqaVMU4aqtBi1Wo1KVUpJcTHqsjLUBw7QKkkMJxLEolFFVhAXGBoaIhAIFHIYxOPxILc6cLtlbFYrdrsds9mC2WzGYrHcwWzBZDLhaGujs7NTeXP8+PtKDgcGBpQu+/s/QHC5XMiyTJu9FafThcPhULDZbIq4gM1mv3e2Wq1KAaPRqIw4OjqmxOhM8gzxeAJBr38dUdRjMBgwiOKd3WBAkgyIonhfJElCp9MpIyaTSSXo4+PjjIyMIHw8epbTyXOcHp/kk7FJTo1O3OUsk9MXmPr0M879i6nznzMxNcPXl6+wlrnOSuoaP12/wdylr/gL+wTYL61udE8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fdd9772e2302fbd5571f525932f63f17/e4706/ISpy_mainview.avif 230w", "/en/static/fdd9772e2302fbd5571f525932f63f17/d1af7/ISpy_mainview.avif 460w", "/en/static/fdd9772e2302fbd5571f525932f63f17/7f308/ISpy_mainview.avif 920w", "/en/static/fdd9772e2302fbd5571f525932f63f17/48bb5/ISpy_mainview.avif 1081w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fdd9772e2302fbd5571f525932f63f17/a0b58/ISpy_mainview.webp 230w", "/en/static/fdd9772e2302fbd5571f525932f63f17/bc10c/ISpy_mainview.webp 460w", "/en/static/fdd9772e2302fbd5571f525932f63f17/966d8/ISpy_mainview.webp 920w", "/en/static/fdd9772e2302fbd5571f525932f63f17/c5fc0/ISpy_mainview.webp 1081w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fdd9772e2302fbd5571f525932f63f17/81c8e/ISpy_mainview.png 230w", "/en/static/fdd9772e2302fbd5571f525932f63f17/08a84/ISpy_mainview.png 460w", "/en/static/fdd9772e2302fbd5571f525932f63f17/c0255/ISpy_mainview.png 920w", "/en/static/fdd9772e2302fbd5571f525932f63f17/71ee9/ISpy_mainview.png 1081w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fdd9772e2302fbd5571f525932f63f17/c0255/ISpy_mainview.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "add-an-instar-hd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-an-instar-hd-camera",
        "aria-label": "add an instar hd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add an INSTAR HD Camera`}</h3>
    <p>{`After you installed the newest version of iSpy please open the program.`}</p>
    <h4 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h4>
    <p>{`Click the `}<strong parentName="p">{`Add`}</strong>{` button and choose `}<strong parentName="p">{`IP Camera`}</strong>{` to add your INSTAR HD camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/41ccfd79c1afee526c90bbf14e6c6521/cd138/ISpy_addHDCam_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACXElEQVQ4y42R609SYRyAzz9oH9ME3cDbVz/ZnM5Zhg0ci+RwOIfAwEu52GRtqdSmlZd5yQsYmZYZVCgORUQ0FPFp55AsKzc/POd3ed/32W/nJ3z+EuXHVoLETpKjnyccHefInebJF+CsAPmzghZP8gXyhXNOf9cFoHBe5Ez9AF+jMYS5yAafottsfEvwPZEiurXL2macselFpubDjE/NMzG7TPDtDG+mF3g1McvkXIjZpQizi0VmFt6zsLLOi5fjCCuRVeLbOyT39tlNpclkj1lcCmGzPcDhcOBwiIiiiKTmoqj11Fq020vY7XackoTJZEIIra4Ti8aIx7dIpfY5zGYJh1e0hy6XC0VRUJSLqJRq9ewCtefxeLBYuhBWP66RTCZJp9OkUimymjCsTaG4/hb9H1mWcbvdWCwWhHdLy8RiMU2qCg8PDwmFQkXhNWR/Cs1mM8KHjU32dvc4ODggk8mQy+WIRCIloXr5OpSEz4YCjI6OMjI8zMjwCMFgkEAggCRJ1xL9M6G6JW2bkqTFC5H6k9VLV6GeqwtxOp0aam61WhEedndj7eqis6MDU2cnd9rbaWtro6Wl5UpaW1tpbm7WluD1erUBfD4fjY2NCPUNDdTX1VFbU0NtbS1GYw1GoxGDwYDBYNRQ68vUUF1drcn7+vqRnTJ+v5+KigoEXeUt9HpdEV0xVqlU6anS67Vap6u8hNorL7/J7aYmbTKL2YzNZuNGWRmCKHtwuh4jKT0obh+K24v8yItD6Sn1+gYG6e1/Sm//YJGBQbx9T/APPWfs9aSWd4syd+/d5xeZXfgET3bLRgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/41ccfd79c1afee526c90bbf14e6c6521/e4706/ISpy_addHDCam_01.avif 230w", "/en/static/41ccfd79c1afee526c90bbf14e6c6521/d1af7/ISpy_addHDCam_01.avif 460w", "/en/static/41ccfd79c1afee526c90bbf14e6c6521/7f308/ISpy_addHDCam_01.avif 920w", "/en/static/41ccfd79c1afee526c90bbf14e6c6521/bffb7/ISpy_addHDCam_01.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/41ccfd79c1afee526c90bbf14e6c6521/a0b58/ISpy_addHDCam_01.webp 230w", "/en/static/41ccfd79c1afee526c90bbf14e6c6521/bc10c/ISpy_addHDCam_01.webp 460w", "/en/static/41ccfd79c1afee526c90bbf14e6c6521/966d8/ISpy_addHDCam_01.webp 920w", "/en/static/41ccfd79c1afee526c90bbf14e6c6521/ea5cd/ISpy_addHDCam_01.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/41ccfd79c1afee526c90bbf14e6c6521/81c8e/ISpy_addHDCam_01.png 230w", "/en/static/41ccfd79c1afee526c90bbf14e6c6521/08a84/ISpy_addHDCam_01.png 460w", "/en/static/41ccfd79c1afee526c90bbf14e6c6521/c0255/ISpy_addHDCam_01.png 920w", "/en/static/41ccfd79c1afee526c90bbf14e6c6521/cd138/ISpy_addHDCam_01.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/41ccfd79c1afee526c90bbf14e6c6521/c0255/ISpy_addHDCam_01.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h4>
    <p>{`If want to use the `}<strong parentName="p">{`MPEG`}</strong>{`-Stream then type username and password into the given text fields. Type the complete url into the MPEG-URL field. The url should have the following pattern: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://[Camera-IP]:[Camera-Port]/videostream.cgi?[Parameters]`}</code>{`. Then press `}<strong parentName="p">{`Ok`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/cd138/ISpy_addHDCam_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAChklEQVQ4y5WSW0sbURRG50cJXgr+BB+11Eiai6BvPln0ocVChYZILmNiYqNJjDcsFgqWGFS0ZqzGGk2i0scWLLTYVoyOxiQzs8oc07st9MDi22efzcfM3ls6/nyCennFuXpJVTOoaAaaDlUDdAOquoFm8D1vqpk3+IH5bp73H46RVjZ2ULZzrG1m2czuk97Osbl7yOrWHsp2ntWN16QzOVLrW7x8lWUpnRH5zN4hmd0DtrIHbO7ss5N/w4tlBamvrw+Hw4Hdbueu1crttja6urqQZT8ejwef14vX68Xv84nY5/OJu/nm9XiQZZlhk2EZn9+PZBo1NDRQX19PS0sLk4kECwsLFAr75HI58vm80N8pFApkMhnGx8cJh8OCSCSC1Ol00tzcTFPTLTo7O9E0DVVVUdVzSqUSV1dlyuUalco15bLomVkXjUYZGRkRhqOjo9df2NjYSF1dHVarlYsLlXdHJ7w9Uvn4SaWoXlKqGpSq+rVWdDRdF4bFYvFPw8HBQfr7++np6cHtdmMYBqen53w5OaN4plKpVtF1XeR1w7jWfxma/x2t9WF+fl4U6rqGplWo1sxu4q+G34JAIMDc3Bz/c9SLCzGUYDAoTM1Y8ssybpeLh/cfiFVYWloimVxkfX0dRVFIp9M1VVCUNGtrCslkilQqxeLyKrH4BKFQSJgNDAwgmTtnTtfpcOB0OsVOmthsthux2204nA4xQLP3ExMJwqEwMzMzdHd3I1na2xFYalqLOywWgRm3t9/5BbOmrbWVe729xONxXK7HDA25sXR0IAVCY4yMRgmGxwlH4oQjMUJPYuIucmNxElOzTEz+xNQs8cQ0T589Z2U1TSwxjU8O8sjl4SuW+iHOMK854QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6128fe5ee4c74b648f047cdc807e3f2/e4706/ISpy_addHDCam_02.avif 230w", "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/d1af7/ISpy_addHDCam_02.avif 460w", "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/7f308/ISpy_addHDCam_02.avif 920w", "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/bffb7/ISpy_addHDCam_02.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d6128fe5ee4c74b648f047cdc807e3f2/a0b58/ISpy_addHDCam_02.webp 230w", "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/bc10c/ISpy_addHDCam_02.webp 460w", "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/966d8/ISpy_addHDCam_02.webp 920w", "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/ea5cd/ISpy_addHDCam_02.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6128fe5ee4c74b648f047cdc807e3f2/81c8e/ISpy_addHDCam_02.png 230w", "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/08a84/ISpy_addHDCam_02.png 460w", "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/c0255/ISpy_addHDCam_02.png 920w", "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/cd138/ISpy_addHDCam_02.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d6128fe5ee4c74b648f047cdc807e3f2/c0255/ISpy_addHDCam_02.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If want to use the `}<strong parentName="p">{`H.264`}</strong>{`-Stream then type the complete rtsp url into the URL field. The url should have the following pattern: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rtsp://[username]:[password]@[Camera-IP]/11?[Parameters]`}</code>{`. Then press `}<strong parentName="p">{`Ok`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3b750059a42268f07723e39660ae1d98/cd138/ISpy_addHDCam_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACmUlEQVQ4y5WSy0tbURCH7//kwsfCP8GlSo2kJlGSnSuLLlosVGiI5KUxN42PxPrASqFC0aCiNddqrI88lK7qSiJqfYQk5saY+5V7NNbSuujAx8yZGX7MmXOk058X5K5VsrlrSrcaN7cat2UoaVDWoFTWuNV4yOtez2v8Rq/rdnR8irS8vo2yFWd1Y4eNnRTRrTgbuwesbO6hbCVYWf/G2macyNomX77usBiNiXxs74DY7j6bO/tsbKfYTnzn85KC1N3djdlsxmQy8dxopLm5CZvNitfjwel04na5cLlceNxuEbvdbnHWay6nE6/Xy4DOgBe3x4OkC9XU1FBdXU1DQwPhcJi5uTlSqRSJRJJEIkE8nmAvHif+iGQySSwWY2RkBFmWBcFgEKndYqG+vp66ujo6OjrELoo3Jc4zOc4uMpyeX3GVveYqV6CgFindlCgWi6Ivl8sxOjrK0NCQEAwEAncT1tbWUlVVRVtbGxU7OTkhnT7m9OyMy8srCqr6UNO0u1fIZDJ/C/b19dHT00NnZycOhwNVLZLNZkmn0xz+OOT8/EJMUigUKJc1IVYul58W1O+t7yEgy8zOzqKqKvl8nqOjIyFcmagiVOFJwUowODjIzMwM/2O5fF4M4/P5hKgeSx6vF4fdzuuXr8RXWFxcZH5+gWg0iqIoj7yCokRZXVWYn48QiURYWFphLBTG7/cLsd7eXiSr1Up7ezsWsxmLxSL+pI7+QP/CZGrDbDFjNBrF7sPhcWS/zNTUFDabDcnQ0oLAcO/v41aDQaDHLS3P/kDvaWps5EVXF6FQCLv9Lf39DgytrUiD/mGGAqP45BHkYAg5OIb/3Zg4i9xwiPGJacLvHzExTWh8kg8fP7G8EmVsfBK318cbu5Nf2ZYgfN3IrYgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3b750059a42268f07723e39660ae1d98/e4706/ISpy_addHDCam_03.avif 230w", "/en/static/3b750059a42268f07723e39660ae1d98/d1af7/ISpy_addHDCam_03.avif 460w", "/en/static/3b750059a42268f07723e39660ae1d98/7f308/ISpy_addHDCam_03.avif 920w", "/en/static/3b750059a42268f07723e39660ae1d98/bffb7/ISpy_addHDCam_03.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3b750059a42268f07723e39660ae1d98/a0b58/ISpy_addHDCam_03.webp 230w", "/en/static/3b750059a42268f07723e39660ae1d98/bc10c/ISpy_addHDCam_03.webp 460w", "/en/static/3b750059a42268f07723e39660ae1d98/966d8/ISpy_addHDCam_03.webp 920w", "/en/static/3b750059a42268f07723e39660ae1d98/ea5cd/ISpy_addHDCam_03.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3b750059a42268f07723e39660ae1d98/81c8e/ISpy_addHDCam_03.png 230w", "/en/static/3b750059a42268f07723e39660ae1d98/08a84/ISpy_addHDCam_03.png 460w", "/en/static/3b750059a42268f07723e39660ae1d98/c0255/ISpy_addHDCam_03.png 920w", "/en/static/3b750059a42268f07723e39660ae1d98/cd138/ISpy_addHDCam_03.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3b750059a42268f07723e39660ae1d98/c0255/ISpy_addHDCam_03.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h4>
    <p>{`Type a name for the new added camera into the name text field. Then press `}<strong parentName="p">{`Finish`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/47225219b09049bb1ef844de4f19c0be/cd138/ISpy_addHDCam_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC+ElEQVQ4y2WSW28bVRSF5xdRWt7K5bnhJp7qkiG9JIS6MagqTVoqNYq4pUnUgDCK7VR1bMmxlUg88YiggFpFUIJC2gAlTcd27TQZj+3x2J6b7ZkPzUkCRWxpa2+do/m01pojVap1TMuhbdr0POj2fGy3S9t2sZwObcvBtF1api32lmVj7d/ZTgfPR3RQpScq0q2VX7n98xrf3bnL7bvrfL+yysPCNsXdOqWdGo+faOxUDR5tq2yrdZRtlR2tQc0wqdRbrK4/YGX1Hr/89gff/PgT0uVLl7j4/mUmxj/kyugFpj6aoKAo1Gs1GrouZr2+t+t6nYZoHcs0qVY1kskk0egXRKNRbiaTSKHQCfpPDHJ1bILx0YtEZ65TUTU838e2HYrFIqqqomkamlbFtCwOKjhPp9PMz88TTyTI5XIBMMTwmXNMfXydqU+m+fqrb7HaLr7v4TgOlmXhui7dble06/ri7gCYSqUELB6Pk81mkU6ePMWrx15m+tqskH1//TGu26XX69I0DPL5vFDXarVoNpsC6vv+f4HxOLFYbA94enCIw4ee5coH41w4f55MZglVrdBut0VXKnu7aZpiBtCnLf8PODg0xNHnX2Jo8ByHnjnMG68fp5DPC0UBILBr2zau2/lH3dMKgwwT+5YXFxeRTkfe4+gLL3Ks7xWOHHmOvr7XUBRlP6+OyDGYnU6QYU/APO/fDBcWFgRsbm6O5eVlpKvXZgi/c5ZTsowsywwPv025XMbzfGFT13XxI/bKF+cHwN3dXTKZjFAYPJ/JyUmkyMgI70YiREYihMNhRsfGKJVK9Ho9YTkAdjouluXhuD0BPaharUo2mxPApaUl8b0ky/3097/JW7JMKHSccPgsWw83xSOuahpGoyGUBpnq9RaG4dLQDYymQXmnwmImw8z0NJ/NzjIwMIA0l0gSv5EidiNF4maaZDrL6toG937fZO3+Azb+3CJfLJMvllAKZbaUEptbBTYfFdn4S+HWD3dIZ3JEv4zx6czn/A1RKlzWnwx9+AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47225219b09049bb1ef844de4f19c0be/e4706/ISpy_addHDCam_04.avif 230w", "/en/static/47225219b09049bb1ef844de4f19c0be/d1af7/ISpy_addHDCam_04.avif 460w", "/en/static/47225219b09049bb1ef844de4f19c0be/7f308/ISpy_addHDCam_04.avif 920w", "/en/static/47225219b09049bb1ef844de4f19c0be/bffb7/ISpy_addHDCam_04.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/47225219b09049bb1ef844de4f19c0be/a0b58/ISpy_addHDCam_04.webp 230w", "/en/static/47225219b09049bb1ef844de4f19c0be/bc10c/ISpy_addHDCam_04.webp 460w", "/en/static/47225219b09049bb1ef844de4f19c0be/966d8/ISpy_addHDCam_04.webp 920w", "/en/static/47225219b09049bb1ef844de4f19c0be/ea5cd/ISpy_addHDCam_04.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47225219b09049bb1ef844de4f19c0be/81c8e/ISpy_addHDCam_04.png 230w", "/en/static/47225219b09049bb1ef844de4f19c0be/08a84/ISpy_addHDCam_04.png 460w", "/en/static/47225219b09049bb1ef844de4f19c0be/c0255/ISpy_addHDCam_04.png 920w", "/en/static/47225219b09049bb1ef844de4f19c0be/cd138/ISpy_addHDCam_04.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/47225219b09049bb1ef844de4f19c0be/c0255/ISpy_addHDCam_04.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "advanced-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#advanced-settings",
        "aria-label": "advanced settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advanced Settings`}</h3>
    <p>{`iSpy comes with a wealth of feature that cannot be covered in detail here. Please refer to the `}<a href="http://www.ispyconnect.com/userguide.aspx" target="_blank" rel="noopener noreferrer">{`iSpy User Guide`}</a>{` for a more in-depth tutorial. Also note that iSpy supports PLUGINS for added features like License Plate Recognition, Face Recognition, Barcode Scanners, Text Overlays, etc. You can also use the software to make your IP camera available for video chat programs like SKYPE.`}</p>
    <p>{`The following section is supposed to give you an overview over the advanced features you might want to use with your INSTAR IP camera. You can enter the advanced camera setting by hovering your mouse over the camera video and clicking on the Cog symbol in the context menu.`}</p>
    <h4 {...{
      "id": "camera-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#camera-settings",
        "aria-label": "camera settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Settings`}</h4>
    <p>{`The Camera Settings tab allows you to change basic video options like the frame rate and text overlays. You can also edit the video source if the camera address or login was changed. Add a privacy mask to the video image or activate the microphone function if not activated automatically.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/126354c8b9ddb804cfe18a1843d08802/33d1d/ISpy_advancedHDCam_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC7UlEQVQozz2SSUwTURjHx8SbFy+IEg+gIi5EKYK0iGETWlrKviiKJkAL4km2srgQEo2yFGgEkYgGkX0JiBRB2beyKQdP6FXSYGHK4PTNvJl5z7QD/PPl5Tu83/u+/z+PaGrpbm4faG7rb+8Zau0eausxtvcOt/aOtPRPNvdNNPeNdxoXur4sdg6bxOowLnQMm7oGpzu7jcSL8pqKSkNllUFfXVdeWVtT8/r5S71xdMxiIc3mv5Yt6+bmtmXLatmyms2Wzc1tkty1bFkxwms/14nchzmpKXciFaqYyJj46ER1RJQiNOb9u88YI0EQAAA2YGMYhmXZXYpiGIAxFgQBYzy3+J0oKX4UFBji7OTifuqsr+elYG+Jm4t7VcVbxyUEAOAghzFGdmEIMUJ78PzSGlFcVBIeEnrR9aRcKpF5elxxdz1y+FBZ6VNBQBzHbfzZ+LX+myRJiqJIkoSOh/bhH0ShrvhmpFwlcbsVdN7v3HGl5ETqdadPH/WA5VjWvi0AgOcFnuch5GiadthBe3BJ8ePQAN8LxwhdgnNpsmu91stw7/LQh1obA61W0mYDEEKWZTmOFxwS7ezBublFZdlRz5LPGLKvND7w0WvCpKeP6vILEMY0TYskwzAQcjzPi+bFxg7n5xXmZqZkJQdn3pbnpMgDvT0IgtAVPhEEBCGk/9EURUEWi7KHtu95YWmN0OUVxMYnSa8FyPwDfK5K/WQyiZdXlb5WQIhhWStp3d4meYFnGAQ5JKYuTp41rRLpGempd1OTkhPjE+Li4mNjYqODQ4PrGhp3qV1gAwAwADA0Te/sAAAgxvbJIjw4/I1QqCKUqghlpFKpVinVKnW0WoQP1kMYc/a4eGT/NQJCgggPDI0Q4YqwMPmNgwpXhMv8pa/q32CMAQ0gYJEj233PGPGYBawDHiXSNdq0DI391GjSMrTarPtpGenVhvrZ+dXxyfmJqYXp2eWZueWZuZWZuZWpmeWxCdPXsdnJaVNDU8t/zaFOzwE3rYcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/126354c8b9ddb804cfe18a1843d08802/e4706/ISpy_advancedHDCam_01.avif 230w", "/en/static/126354c8b9ddb804cfe18a1843d08802/d1af7/ISpy_advancedHDCam_01.avif 460w", "/en/static/126354c8b9ddb804cfe18a1843d08802/7f308/ISpy_advancedHDCam_01.avif 920w", "/en/static/126354c8b9ddb804cfe18a1843d08802/d31ba/ISpy_advancedHDCam_01.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/126354c8b9ddb804cfe18a1843d08802/a0b58/ISpy_advancedHDCam_01.webp 230w", "/en/static/126354c8b9ddb804cfe18a1843d08802/bc10c/ISpy_advancedHDCam_01.webp 460w", "/en/static/126354c8b9ddb804cfe18a1843d08802/966d8/ISpy_advancedHDCam_01.webp 920w", "/en/static/126354c8b9ddb804cfe18a1843d08802/7ed5b/ISpy_advancedHDCam_01.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/126354c8b9ddb804cfe18a1843d08802/81c8e/ISpy_advancedHDCam_01.png 230w", "/en/static/126354c8b9ddb804cfe18a1843d08802/08a84/ISpy_advancedHDCam_01.png 460w", "/en/static/126354c8b9ddb804cfe18a1843d08802/c0255/ISpy_advancedHDCam_01.png 920w", "/en/static/126354c8b9ddb804cfe18a1843d08802/33d1d/ISpy_advancedHDCam_01.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/126354c8b9ddb804cfe18a1843d08802/c0255/ISpy_advancedHDCam_01.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Edit basic video and audio option of your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/feee5958aa155986175d63755e16a6f7/4dba2/ISpy_advancedHDCam_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADM0lEQVQoz2WOXUxScRiH/zety9ZFZa2aq1yuVVPLJGdlQwJBMtOczumafJTryrI0tWVpVqZlpA4TFUiUD/lSaXqOnIORTkjFTK02NbdKUMHAw8cBFFu0Wlu/PRfPxftsL2gWSgUdKkGHsl2mEUl72mUasaKXJ5TVNbXV80R1vDYuX9IokP5BwuWLuXxJHa+9sVUCHlXVPqnmVNdwnj6rr6p+/qy2vqqG09TcqpAr5J1ylVKlkCuVSpVKqeqUyRVypVrdpVKqYQgWi+WgIP9GTlY2PTEphZ6SdiH9PPU8nZL2oJyLIFqdTgdBUG9fHwRBMAxrNK8hCBoYGEBRVK/Xt7YKQWlxafwZ4o5tu8L2hUUfPhofGXFgT3hBfhmColoE6e2D4H4tguoQBEVQHQwHBUH0b97wBW2guKiETEw4HLqbQog8eeRg1IG9WzZvuspiaLVaRAt3yqSiV8Ke7q7XPd09Xep+CNKhWhTp1+uDcVFhcUYSmRYRmhEfHhO+gxYVknt2Z8vToiGDadBoMoxNDhrHh0c/GMemDGOTb40To+8/jkx8mpiYFonEoKT4TsKp6EPbwe1LIfczQxvYES8uR4hqS43T36B3c4bp70MfzKbZlfFZ+8hn69D00vCU2fBpeWRyoUUgBddv3C6/llyZEcbJO/byWnQNMyFm/1ZW7mWLzTk1s2BZxmbmzcaxyaUVp8O1Znf6f2A+mwO3Yn6RrAvcLCi6fiWLnX6WnUXOzySfjjwIAGCy8ladzqVFC+7BTaZxjUYTCAQ2/i7oEnk3KCy4lZKaToiNI8TGHT9BiCGcPBYVVVJSarVjS1Yrtop5PHjwPvBP+8vbZWrAYDKyc7LTMy6lpl28mJqSfCGZSCKWV1RYrKsLi8tul9sdjP/d77ihiQ8otEQqLZGaRKXSaVQ6jZ5MJ5KIFZWVNrtr2brixJy41/dfvL6xsVHX2ALOUUgkcsJfzlFIcadi75bds9mwVQfmwlweN+5fC/jX1v1r616vD3d7vMFfONxmwGCxc5msX7DYuSw2+2oeg8V8+Lj6y7zF5/a67C6HzeFx4W4n7vN4v5ptw1Nzc7Pz5u+LDTzhT4J0GjLHF4edAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/feee5958aa155986175d63755e16a6f7/e4706/ISpy_advancedHDCam_02.avif 230w", "/en/static/feee5958aa155986175d63755e16a6f7/d1af7/ISpy_advancedHDCam_02.avif 460w", "/en/static/feee5958aa155986175d63755e16a6f7/7f308/ISpy_advancedHDCam_02.avif 920w", "/en/static/feee5958aa155986175d63755e16a6f7/3c69e/ISpy_advancedHDCam_02.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/feee5958aa155986175d63755e16a6f7/a0b58/ISpy_advancedHDCam_02.webp 230w", "/en/static/feee5958aa155986175d63755e16a6f7/bc10c/ISpy_advancedHDCam_02.webp 460w", "/en/static/feee5958aa155986175d63755e16a6f7/966d8/ISpy_advancedHDCam_02.webp 920w", "/en/static/feee5958aa155986175d63755e16a6f7/ee998/ISpy_advancedHDCam_02.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/feee5958aa155986175d63755e16a6f7/81c8e/ISpy_advancedHDCam_02.png 230w", "/en/static/feee5958aa155986175d63755e16a6f7/08a84/ISpy_advancedHDCam_02.png 460w", "/en/static/feee5958aa155986175d63755e16a6f7/c0255/ISpy_advancedHDCam_02.png 920w", "/en/static/feee5958aa155986175d63755e16a6f7/4dba2/ISpy_advancedHDCam_02.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/feee5958aa155986175d63755e16a6f7/c0255/ISpy_advancedHDCam_02.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a black&white image file to the camera to mask areas of the video where privacy is concerned.`}</p>
    <h4 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h4>
    <p>{`iSpy comes with a range of different motion detection algorithm built in. Each motion detector has different performance impacts and is suited to different purposes.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Two Frames:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Probably the most common type you would use. iSpy just compares the last frame to the current frame.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Custom Frame:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iSpy stores one frame in memory and compares subsequent frames to this - good for detecting if things change - say someone takes a bag out of the scene.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Background Modelling:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iSpy takes a custom frame but adjusts it over time to morph it towards the current frame. This is good when you have something in your scene that is constantly moving - iSpy will learn to ignore it.`}</td>
        </tr>
      </tbody>
    </table>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d143836639af53ff2383658d910c933/4dba2/ISpy_advancedHDCam_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADU0lEQVQoz02SaUiTcQDG3yiIqA/1oRSJTktHh01cLjGbuTnd2VozK81SN4+EyqMtLSrBUpulW5rZNjUvyqPLTbPDeSSadqyMkChBymi16732vvpu/zD70MPD8+0HDzwPpG9orW95WN/8oKXN2NRqbGkzNbeb2oz9j168Nr4Y7TKPPh/68Gxo3DRgMfZbTAPv5/Np72hrRzdUXFp+Va1Rl2muXa8sVVdcL68sVmveWT66MXTajlqmHGOT9onvTtiJ4ygOO5yw0zVDEACA+4+fQLmnc44eSRTyBBKhRCaJE/PFoljZyMh7D0WOfJpq7p/o/eJ+Pe22ohjiJeyky066HDMujCJ1+jtQQf65yD17fVb6bdqwmbF1O4u+w38tzdw7DACY/jb1dWLc7bJ5ZgmKmvV4PR6vh/JSGI4RM5ShrhnKP1sQHcXesm41l0nftS0g2H/N8qVLukxdJElarb+sVpvN7kBRlHDjhNuNoRhJkuCv9LVNkEqZHy+I4dPXH2LRQgN9eTt8kyJXvx3upbwAwzCbzeZ0OGEYRRASRQkEwWAYpijqH1yQf569m0FbBSmlPhfj11XKg7QpDMtwHwBgcGBAKj6Qfjw9LSFFdVJrKG8oVKqKzytxHAcA6GoboZycs4WZoqK4DZqM4JosRllq1G6ab2enCQDQ3t6x3s9vPyssbEsgbWPA5SxZXgJPIROiCDwHGxqhvFxl9olj8iR+WmJs9mFuBH0zBC00mnoAAH093amc4PgoOme7z42TzPtXZMJwepY0GkeRf7WVuWckB4+ERnKY4REhO5k7mcyQEIbZPAgAeNxxTxy0oDE7cEzN7SuWMAL8woICr2YewhEXAOCWrh5KTk1JPJpwME4qlUr2SyXifSI2l/NyaG6qyTfdDSf8X5WxtMeZKxZDCxYtC9+67YwojETnahcWlUAx/FgeP5Yn4PGEfJ6QLxAJ2NzoeXjq8/izjuph090LKpVckVF6xVBTUdt6o8RDzj3sUlEJFB3D4XDZ/zuCFWHuG0Rc6I9pqwsmZing8c5PC7xe4CZmf/38jWGksuAClCJXJKfK/1qRLFfI09PlaWllFZV1Tfd0dY23DXdq9PU3bxu01fqqmlpNlU59TVuurdZW6TOzTv0BRSL2zkIXCTUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d143836639af53ff2383658d910c933/e4706/ISpy_advancedHDCam_03.avif 230w", "/en/static/4d143836639af53ff2383658d910c933/d1af7/ISpy_advancedHDCam_03.avif 460w", "/en/static/4d143836639af53ff2383658d910c933/7f308/ISpy_advancedHDCam_03.avif 920w", "/en/static/4d143836639af53ff2383658d910c933/3c69e/ISpy_advancedHDCam_03.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d143836639af53ff2383658d910c933/a0b58/ISpy_advancedHDCam_03.webp 230w", "/en/static/4d143836639af53ff2383658d910c933/bc10c/ISpy_advancedHDCam_03.webp 460w", "/en/static/4d143836639af53ff2383658d910c933/966d8/ISpy_advancedHDCam_03.webp 920w", "/en/static/4d143836639af53ff2383658d910c933/ee998/ISpy_advancedHDCam_03.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d143836639af53ff2383658d910c933/81c8e/ISpy_advancedHDCam_03.png 230w", "/en/static/4d143836639af53ff2383658d910c933/08a84/ISpy_advancedHDCam_03.png 460w", "/en/static/4d143836639af53ff2383658d910c933/c0255/ISpy_advancedHDCam_03.png 920w", "/en/static/4d143836639af53ff2383658d910c933/4dba2/ISpy_advancedHDCam_03.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d143836639af53ff2383658d910c933/c0255/ISpy_advancedHDCam_03.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Experiment with different motion detection algorithm to find the one that works best for your surveillance.`}</p>
    <p>{`The sensitivity can be set in a Trigger Range, allowing you to set a minimum and maximum level of motion detection. The cut-off function for high detection levels allows you to minimize false alerts due to e.g. changes in lighting conditions (passing cloud during the day or passing car during the night). All changes that effect the whole video image can easily be removed as an alarm trigger by the set upper detection level. The lower level defines the minimum amount of motion that has to occur to trigger an alert.`}</p>
    <p>{`Set an upper and lower detection level cut-off to minimize false alerts.`}</p>
    <p>{`The HSL Filter tells iSpy to ignore certain colours or luminosity ranges when looking for motion. There are many ways this can be useful, for example ignoring constantly moving objects (like trees or clouds) or Tracking objects of a specific colour (like your red car in a parking lot). Use the colour wheel on the left to select a range of colours to either ignore, or track (depending on the drop down selection for "Fill type"). You can then specify the minimum and maximum levels for saturation and luminance. A live preview of what the motion detector is seeing is provided on the top right. Here you can see that the detector is set to only look for red colours and ignore everything else - this means that it will only record and alert if something red enters the frame.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/4dba2/ISpy_advancedHDCam_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYUlEQVQozwFWA6n8AJ2jq6GnsaGmsKestaWrtaestqWstra8xcTL1MPK08PK1MbN18fO18bN1sXM1cbN1sXM1sTL1cbM1rG3wQCIipCLjJKMjZKOj5WKjJGQkJaJjZPR0tb19PTz8/Ps6+zu7e7w7/Hx7/b19Pby7/Dy8PH49/f///+0trsAdHJzXltdTUpMUU1QVlJXUExQUEtXzMzU+vr48O/w8PDw8/Pz9PT27PX38/737vnx7vnx9/j4/f7/oKCjAHt6fEE/QhcVGiYiJzAnKTYnJhkWHb6+wf///+/z9fj5+vr6+f7///7+/P3//v3//vz9/Pb29fv8/aSkpwB6eXtGQ0QtIh8/LiZ6eHmvtLyqqb/P0OfY3urS2+fGztjQ2OLZ4OrW3+rQ1uLj4+f8+/z+/v7///+jo6YAeHd6WExITjUlSS8WsKqm////4f3O6vnF/Pv/09DUSUVGWU5QXFhbkIOAc2Fd09HS9/j4+/v6////o6OmAHt6fUQ9NSsYAG1IBsyvnsT++x7/RdjCAP/QwM/S2VM4MEgxJ2dMPn5hU1tAOp+Tj4d9ePLx8f///6KipgBzc3mCak2GWSaJXiTev6au8/8IbfjeAI//p7zT2tdpRC6fbU54TTgcFRJkPjeTfHVlU0rz8vH///+ioqUAdXN3cmtgaF9KXlA6vLGs/v//p4v84IP8/vP96eTkwaugtp6UX1xee3p8p4yKmYN8X0g7+vf2////o6KnAHd0dlFXWzpBRSwuMJqZndzZ3Ojp3Obv4eXl5/Du8/Lz+Pf3/Pz8/f79/f///7Chm3laSPv39v///5+fowBiZGNdXlxZWVZJSkiioaLOzdDU0djh2uHo5enu6+/r6Orw7fDw7fD18PP///+olo9uQS389/X///+HiYsASEtJSkxKTU9NPkE/qKiq5+To4+Dk5eLm6Obp5+Xo5eLl7ert7Onw6+ft6efrwbi3qpCH/fn4////h4iLAEZIRUVHREZIRUFDQGJkY3x/f3F1dc7P1PHv9efm6+zr8PTy9/Lx+O3s8ufm6/T0+Pn6/ezq7Pn3+n5/ggBjZWZiZGZiZGViZWdfYmJfYWFfYWGSlJepqq6kpqqkpqqkpqmkpqmlp6unqa2en6STk5eYmZ2cnaJucHPXVTTSZxnKfgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/e4706/ISpy_advancedHDCam_04.avif 230w", "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/d1af7/ISpy_advancedHDCam_04.avif 460w", "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/7f308/ISpy_advancedHDCam_04.avif 920w", "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/3c69e/ISpy_advancedHDCam_04.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/a0b58/ISpy_advancedHDCam_04.webp 230w", "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/bc10c/ISpy_advancedHDCam_04.webp 460w", "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/966d8/ISpy_advancedHDCam_04.webp 920w", "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/ee998/ISpy_advancedHDCam_04.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/81c8e/ISpy_advancedHDCam_04.png 230w", "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/08a84/ISpy_advancedHDCam_04.png 460w", "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/c0255/ISpy_advancedHDCam_04.png 920w", "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/4dba2/ISpy_advancedHDCam_04.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f0fc8613b94cfa7900f37820cdbaf5e8/c0255/ISpy_advancedHDCam_04.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The HSL Filter allows you to let iSpy either ignore colours or only react to a specific colour in the video frame.`}</p>
    <p>{`iSpy can monitor specific areas of the camera view and ignore others. You can add as many rectangular detection zones as you like (iSpy will only monitor the area within the zones). To add a zone, click and drag out a translucent rectangle. To clear all zones click "Clear Zones". Note that with no zones, defined iSpy will monitor the entire camera view.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5bda72681c0578287089a185a9b6f67a/72372/ISpy_advancedHDCam_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADV0lEQVQozzWRW0xTdwDGz7LsYckyMJtMRDZqoVB7oS1tuQh1PadMEi5yqlMYA9uFhTmdKESB4UAu2lJBYS6SsIsmJiZIXYwCbdVSWrCovIynxS2LD/qwDj09l54rPee/FLcv3+vv+77kg7461nG0/dTR9lPtHT3HO7pPdPQcO9k9+cu0d/7h9N3F6dnIrcCjX+89vhWIev1Rr3/F64t6fdE5/5JrZAJq+tzR3OJsaXEedrQ2NzsdztaGJsfKo1WB5xiajmMYgRMUSVEExzJcgiQTFCXwHABgcuoaVFtTYymvKNIZTAZTiam0SG/UaYyRyJMNgY9jcRwnKSpB08w6zVISi/EExhMMy4gAuC9cguyofZdSlfbulsyMzNzsj1SynK1btgWDSwCARCLBsizHcZIkSUBMSqIoSUkxSdN0UgSei99DKGovVKuzM97TK2SKD7Nk295/+6035+f8giDEYrEXz18QOMHQNMcyPM+xDCMIQioKgJGxCai+Hi0zaPU5W3ertudlpRXtTLco06ORoLTZvL7+EsPiOEERBE/gNIbhOI4nkxv/wXZ0v6ZAnp0GoSXph8ozvqzMadkjWwmHAACRxTBau7/N0db6mfNs19T8zP1rUz+NuwZIkkzBo+NQTS3aUGVs3J3p+ETWVpXbDGvkH7wzO+sDANyc8cp3ZB9AKox58ipk728P7z8J+Ya7Tr5ajwEA3KPjUF1dfTVSjpSoKi2G6nKdMicTgt6Y990DACz6576wqg9+rEaU6T93wf4r3zRW2zqb6kg8vtk8Ae2r22cuLs3NK1DkK+W5CoUiXybbGVwIp2YHA6M9hxeu9/4VuLw8+XWFKgvdC3uONCSIFHzecxGCYdhisZSVlRaXmM3FZpPJuEutDkeWAQB//7n6+4MfX67dfrp8Z0+ZefsO2Y2rV88crGTirwAA3b39kE6v0+l1eoP+tYuMBqVaHQqnfn7+7Ona4wd/rK0G5nwDg0Pnz10J+kIzP3g2GHoTPgtpCzVarUbzv7WFWkVB/kJoiWf4f2IYSTLJDUkSwWuJIuB5MY7hgiB1nj4DWRGbFUasCGKFbVbYhtgqYcTWebp3ZOzysHts2DU67LoweM7z3YCrb9DdN+ju+ra/t2+of2jE/mnjv5/t3TtWrMudAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bda72681c0578287089a185a9b6f67a/e4706/ISpy_advancedHDCam_05.avif 230w", "/en/static/5bda72681c0578287089a185a9b6f67a/d1af7/ISpy_advancedHDCam_05.avif 460w", "/en/static/5bda72681c0578287089a185a9b6f67a/7f308/ISpy_advancedHDCam_05.avif 920w", "/en/static/5bda72681c0578287089a185a9b6f67a/433d4/ISpy_advancedHDCam_05.avif 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5bda72681c0578287089a185a9b6f67a/a0b58/ISpy_advancedHDCam_05.webp 230w", "/en/static/5bda72681c0578287089a185a9b6f67a/bc10c/ISpy_advancedHDCam_05.webp 460w", "/en/static/5bda72681c0578287089a185a9b6f67a/966d8/ISpy_advancedHDCam_05.webp 920w", "/en/static/5bda72681c0578287089a185a9b6f67a/27033/ISpy_advancedHDCam_05.webp 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bda72681c0578287089a185a9b6f67a/81c8e/ISpy_advancedHDCam_05.png 230w", "/en/static/5bda72681c0578287089a185a9b6f67a/08a84/ISpy_advancedHDCam_05.png 460w", "/en/static/5bda72681c0578287089a185a9b6f67a/c0255/ISpy_advancedHDCam_05.png 920w", "/en/static/5bda72681c0578287089a185a9b6f67a/72372/ISpy_advancedHDCam_05.png 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5bda72681c0578287089a185a9b6f67a/c0255/ISpy_advancedHDCam_05.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Limit the detection zone by drawing rectangular shapes on the video frame. Only motions inside a drawn zone will be detected to reduce false alerts.`}</p>
    <h4 {...{
      "id": "alerts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#alerts",
        "aria-label": "alerts permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alerts`}</h4>
    <p>{`iSpy raises alerts for cameras and microphones when it detects movement or sound (you can also raise alerts for no movement or no sound).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Mode:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Choose between movement/ no movement or plugin modes. Click the "..." button to configure various settings.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Continuous/ When Motion Detected/ External Trigger:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`These options apply to plugins only. For example, you may only want to perform license plate recognition (using the plugin) when motion is detected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Actions:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Actions to perform on alert - You can add as many actions here as you like.`}</td>
        </tr>
      </tbody>
    </table>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/33d1d/ISpy_advancedHDCam_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACq0lEQVQoz4WRW0xSARjHz6qXHkpzRZHTgRKgHrkJiqg0LjbXyADbsnkBV602Kw2nk7l0LbFj3lprq5d66M3SphmKLlS8oLX1VA/VQy8tXSs5IBwOHM85X1NcL1r99nv4Hr7/vm/7I1evOeobmusbmhsczhuO1sYm5/WbrY+eDj53zw+OTg+750emlkemll9OLg17loY8/hcT/iGP3+1Z6MLuI1XV9praupraOpvtYnWt3V53qbLKtvz2fSCAf11Z+xmJf18Lr+BEIBQhIiRJxkiSjMdiAPDw8RPkjMmkLS7JkylUCpVaVaiUK6WocnHhXSROP5v9PPox5PkSHftEfPgWJIk4ywIAsCwDAC6sF6mwWnOy0aSDh7gcriAtPZvP46RwJz0zVDz+Y3WVCIeBZYChWYbZTG3BMDQAYD0DiMVilaJoGidFLuQL01P5xw7v37d3fNwDAMFgkCAImqG3pbeHDZoCgK57/YjZbNEopHLekaKc4ydSk/Iykkuykv1zXgBIXNgJu/V9V3c/YrVU5Ioz05IQizr5fDHncinPdpK/5JsGAJqmE3t/DZvOWCrLVBeKuPZT/Ctlghp9bubRA6/dE4nwfy6Xl5tPG0sMhTmlWoWpWCbmcRFkz6uxiVgsFsDx9d0Ir6+TJHW3ZwAxl59VqTUCoVgkysoUCIVCUQY/443XBwBRMkrtRqJnV3cfotfrtVqtRlNYoM7PL8hXqZTZKDo7t/iPt5nN2qDF2Y7I5DKZXCZXyBPmKRVZKDrjmwcAiqIYhmF3kGihxXkLkUglEmnuppItpRKhWOT1+pgNNvALj4QIhmITshtsLMqG8Ai+hlMU29jUiugMRp3eoDMYdHqjTm80GEv1BqOjpQ3rfdCJ9bmw/k6s74+3XT1tHa62js72O5jlXOVvsOUgnZwu2ugAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/e4706/ISpy_advancedHDCam_06.avif 230w", "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/d1af7/ISpy_advancedHDCam_06.avif 460w", "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/7f308/ISpy_advancedHDCam_06.avif 920w", "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/d31ba/ISpy_advancedHDCam_06.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/a0b58/ISpy_advancedHDCam_06.webp 230w", "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/bc10c/ISpy_advancedHDCam_06.webp 460w", "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/966d8/ISpy_advancedHDCam_06.webp 920w", "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/7ed5b/ISpy_advancedHDCam_06.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/81c8e/ISpy_advancedHDCam_06.png 230w", "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/08a84/ISpy_advancedHDCam_06.png 460w", "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/c0255/ISpy_advancedHDCam_06.png 920w", "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/33d1d/ISpy_advancedHDCam_06.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d9a2b5e5c41f0c6399398e6cd129d19/c0255/ISpy_advancedHDCam_06.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Define a criterion that is used to trigger an alert - the default is to detect movements in the video frame.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9707fa89ab0ff0afa1759a644a4608c6/4dba2/ISpy_advancedHDCam_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACy0lEQVQoz4XRW0hTARgH8EP10kPNJK0lypZzanPHXdy8T3YRhNQ2fdDQpVJRoLQcaobSBW9LUYwCX6LHINNIaO6opdls6kNB0OxBEzIlNNt9O2fn9sWUSuyhP7/Xj//H9yFXG8z1puZ6U7PJ3HrN3HrdfLOhsXXo8dNhq/3Ji+lhq310YuH5xMIoNj+KOUZsjmc2xwjmsGJz3ZZBpKq61misM16oq6m5WG2sra27VFlVs7D43uN2b21uhQki4PMHA4GA3x/w4zhO4CE8TBAA8GDoEVJSXFyQr5JLZUq5IkuZnSFTSFCF3b4IwG6sb3g8HpfL7fV6vT6fPxAGFgBYlmEAoNPSh5SXlYnOpHGOHuPGcgXxCSI+Lzaai2HTFEV53O5QKAS/w7IMuxOGoQGgu3cAMRjKJGJxfEy0VMgXJsTxTx4/fOjg+DgGAC6XKxgI0nvDRFA0CQBd9/oRvd6QI0+X8mJyRaeS4jjy01H5qVGOt68BYLdhX1gWWDayfZelHykzlItTBfEcxJDFqciLvazj1RTw52enAYCkKJphGRb2+TtcXGKoLFKcz+XWFvKvFAmMGnHiiSNWqw0AaPp/zaWl+rO6fG22qFAlLc6TpPK4CHJgZGz84yY1s+yd/eLf682Kf27V5/b6CIKMHExfek6ZlZMkTElOTk0UCJOEQkGiYOzl5Dvntw9ffUtb1D7O73gwhANAR08fotFoVCpVTk52ZpZSmanMUGSkoSg28WpzfY0I+P5dm6EphqYAoKm1HZFIJRKpRCqT7pLJZSIUtWFTn5xLyyurQRynGXYPwAmSJCOvarrRjqDpKJoujkB3pKPClOTJqZmtzR9Lzs/raxthnKTCNBWmaZLGg8zPba9r20WSrKmxBVFrdWqtVq3RqjU6tUan1RVqtDpzS1vvwMOevvvdvYOdlv4/7nb1td/parvdeavDYiiv+AXW0xsyDXXhRQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9707fa89ab0ff0afa1759a644a4608c6/e4706/ISpy_advancedHDCam_07.avif 230w", "/en/static/9707fa89ab0ff0afa1759a644a4608c6/d1af7/ISpy_advancedHDCam_07.avif 460w", "/en/static/9707fa89ab0ff0afa1759a644a4608c6/7f308/ISpy_advancedHDCam_07.avif 920w", "/en/static/9707fa89ab0ff0afa1759a644a4608c6/3c69e/ISpy_advancedHDCam_07.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9707fa89ab0ff0afa1759a644a4608c6/a0b58/ISpy_advancedHDCam_07.webp 230w", "/en/static/9707fa89ab0ff0afa1759a644a4608c6/bc10c/ISpy_advancedHDCam_07.webp 460w", "/en/static/9707fa89ab0ff0afa1759a644a4608c6/966d8/ISpy_advancedHDCam_07.webp 920w", "/en/static/9707fa89ab0ff0afa1759a644a4608c6/ee998/ISpy_advancedHDCam_07.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9707fa89ab0ff0afa1759a644a4608c6/81c8e/ISpy_advancedHDCam_07.png 230w", "/en/static/9707fa89ab0ff0afa1759a644a4608c6/08a84/ISpy_advancedHDCam_07.png 460w", "/en/static/9707fa89ab0ff0afa1759a644a4608c6/c0255/ISpy_advancedHDCam_07.png 920w", "/en/static/9707fa89ab0ff0afa1759a644a4608c6/4dba2/ISpy_advancedHDCam_07.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9707fa89ab0ff0afa1759a644a4608c6/c0255/ISpy_advancedHDCam_07.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Define an action that is triggered by a detection - you can add as many actions as you want.`}</p>
    <h4 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recording`}</h4>
    <p>{`iSpy has 4 types of recording mode:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`No Recording:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Triggered manually - either by right-clicking on the camera in iSpy and clicking "Record" or by clicking "Record" in iSpyConnect.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Record on Detect:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Starts recording as soon as motion is detected, regardless of how long the motion has been detected for.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Record on Alert:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is very useful for cutting down on the number of recordings if you have set a sensitive motion detect trigger point.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Timelapse Recording:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iSpy saves a frame on the interval you choose and convert the images to a movie file. Record on detect means record as soon as ispy detects movement. Record on alert means record when ispy generates an alert - alerts can be generated by plugins like face detection or number plate recognition or whatever.`}</td>
        </tr>
      </tbody>
    </table>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/33d1d/ISpy_advancedHDCam_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC3ElEQVQoz22S3U+SURzHn7W1Ni+kcsm0EJ0CKoI84AsoMXnRuUUmYL4EomgrN5tOzU27abVq88q1rup/6K5b0GUIZNq0soucTkkMEMbzwPP+ctoj9rLVd7/z29nZ77Pv+Z3fgcbuTY9Pzo5Pzk5Oz09Mzwl55sHrN4HlyJfA6qfgxre17YPw1l5ocze0uRv8uBPc2Alv7m1/PVh8/gpye4YHvb5Br29oaFTIw6Nu7+jW1mcknc6iCEXiJJ4j8RyeQykcAzwLOIbnGADA4ouX0HW73WS8qtNoG7WN+kaDTqNr0BpCoY0Mghyn0gRJIgiK4wTH8xzHsxxHkBRF0wCAh4+fQS6nU1lbJyq8UFJcUiWR1pZLS8VlKythhqYwDKMoOpfDaJrmOA4AQNM0jhMMIzg/erIAORzO+ro6SfFFWF4hL7tcLi4qLCjw+5fypelUOpFIJuKJRDyOIihFUjTDsOwvuLvb0aKth8svtSpL5VdEugqRWS1eC70jKRpB0OhBNHYYix0exWKxRDxB4AQAgOf5U9jpcKmqKyUiyKE/32csvm2VjtgU6+HVbA5LHafydb+B/IY/aUGA7dcd/Z2NA60lwx0VdzurPG3KGkmR378MAMByGEGQLMtyHIcLIgicYGjmD9zV1X3NZrQalO0mrd2oUUjFZ86eCyy9BQAgGZQkyfxTnfgJYln2FH66AHV33WjSt8jk1QpFTaVMIZPJ5XJFcDUCACBwgmVZ8K9OWhBGZbFYTCZTS4uhWd/U3NzU0KBTa+DI+3UAQN7zf6xwPjUzB2lgjQbWwFo4H1odrIbhcOSDMCqK5liW/1scL9ybET7J1P15SF2vVterhFCr1MJS1arq/IGVTDr7/eAo/iOVRXA0g6EZLItgqSQW3Y9H9w+TSeTO2ARkttrMFqvZajVbbGaLzWprt7V39Pa7B4dGPF6fx+tzDw7nw+P19d/yuHr6XDcHenoH2szWn26iHfAwqrdIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b8014d85e451b7b63651ed9f0a7d77f/e4706/ISpy_advancedHDCam_08.avif 230w", "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/d1af7/ISpy_advancedHDCam_08.avif 460w", "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/7f308/ISpy_advancedHDCam_08.avif 920w", "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/d31ba/ISpy_advancedHDCam_08.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1b8014d85e451b7b63651ed9f0a7d77f/a0b58/ISpy_advancedHDCam_08.webp 230w", "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/bc10c/ISpy_advancedHDCam_08.webp 460w", "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/966d8/ISpy_advancedHDCam_08.webp 920w", "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/7ed5b/ISpy_advancedHDCam_08.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b8014d85e451b7b63651ed9f0a7d77f/81c8e/ISpy_advancedHDCam_08.png 230w", "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/08a84/ISpy_advancedHDCam_08.png 460w", "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/c0255/ISpy_advancedHDCam_08.png 920w", "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/33d1d/ISpy_advancedHDCam_08.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1b8014d85e451b7b63651ed9f0a7d77f/c0255/ISpy_advancedHDCam_08.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adjust the recording settings for your camera.`}</p>
    <h4 {...{
      "id": "ptz-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#ptz-control",
        "aria-label": "ptz control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PTZ Control`}</h4>
    <p>{`iSpy includes an XML definition file for controlling PTZ cameras. You can control PTZ for your camera from this panel, or you can control PTZ within iSpy by holding down the middle mouse button on the camera or from the context menu (for advanced commands like turning on/off the IR light). If your camera has zoom controls, you can use the mouse wheel on the camera to zoom in or out.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/4dba2/ISpy_advancedHDCam_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC2klEQVQoz3VRa0xSYRg+W7/rV2W1XGZl2c3RRGkr8gIiN41YapnmVLxVm7fCW7NZOlMsoViKwqRUWlJ5magVWZAm1h8Nt/4V2tSjHFxwBM/xnPM1Kc01e/bs/fG+e/Y+z/tC6icdWl2Xtr1Tp+9t6+jV6Q3teoPeYOoyfnr5aqR78PPAsHVgeMJgHu8zj/V9GDOYxgzm8YFBy7PnfVB1TX2tTCGrU9y7r6yRyevrldW18i8TXxcQxOFAfkxNzs7OoKgTsS/a7Rhi9yyibhzzAAA6Og1QYV5BcmKSkCcQCUXnRXGx/NgY7nmLZRwAQFFgEUUxbIkgyeVlAsMIHCcpyjsAoEGlgUpLysLPRPhs23XAP4B+9HgYLWj/nkCzaXRy8rvxzcD07Nyc/afNZnN7PEvYEobjJEmSBEECoFJroZLi0qhI1hG/3RwG7eSxg7R9vlu3bNbpdDLZ3bzcnGaN2u5wWq1WDMOBF9RqaWzWQkXSkgRBNJ+290JYYOihHdwgn5QIX4up/05lpUDI7ex8AQBwuVDKa/VfcWnJTdZpeuB2SCr2uZXgp5QEPUijW0fN32xTb41GGIbdbjdFUeR6EAQFQKNaCxUUFFfkxFTG+SuyT6iu0uvSIpmHd/Z092A4tuBYcDqdCIKg6N/NAPw52Erm64XS/Cspksv8zCRu/kUOkxYAQZsM/a8BADiOgw3hFTepH0PSwhui+MTQcDbjFDM4hBHCYAQH09+bhwAA3qP+V/xIpYFS09OSki/Fx4nFYtE5sSj2bAyLwx4eGQUAkOTGYsrbL79dBUXzuTw+lyfg8YR8npAviBGwOFFDH0d+2yYIgiLJNZLEyo+Xl1filFdUQVHRbDaHtZ7MMKbxncnpck3PzMzNz7tWgaIuh8MFw3YYhl2ou7CoDEqTZKSmS7zMSJVkSLKyJJmZMrlS0/q0qaW1uaW1aY3atobmFrlSpVCqHjZqsq/l/gKqH0DvEdgBewAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d6aaacd546fe3d64f7c2e36885e874e/e4706/ISpy_advancedHDCam_09.avif 230w", "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/d1af7/ISpy_advancedHDCam_09.avif 460w", "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/7f308/ISpy_advancedHDCam_09.avif 920w", "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/3c69e/ISpy_advancedHDCam_09.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d6aaacd546fe3d64f7c2e36885e874e/a0b58/ISpy_advancedHDCam_09.webp 230w", "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/bc10c/ISpy_advancedHDCam_09.webp 460w", "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/966d8/ISpy_advancedHDCam_09.webp 920w", "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/ee998/ISpy_advancedHDCam_09.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d6aaacd546fe3d64f7c2e36885e874e/81c8e/ISpy_advancedHDCam_09.png 230w", "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/08a84/ISpy_advancedHDCam_09.png 460w", "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/c0255/ISpy_advancedHDCam_09.png 920w", "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/4dba2/ISpy_advancedHDCam_09.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d6aaacd546fe3d64f7c2e36885e874e/c0255/ISpy_advancedHDCam_09.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The PTZ Settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d4951f8979d7f14631d0f59b3f80b75/72372/ISpy_advancedHDCam_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABkklEQVQoz52Qz0sCQRTH96+JukRQhHUIgiAwgqioTolQHoJOHaJTl+5BODszOzMLi2ZRbuhWKFIW/aDSCHaN3IIi2ky6lJn2Q3ANEaqDigUfeO97+Dzee9w8kABdpZJPWjpUguqqf0fe2A3snG1un1YlGtzY4ixNzV2trW0NddZ2y/yUbaKv0zHYQzGhCFGMi6ByYEwEyk1a653jdYKj0T3TfRENHYRDauTo5vpmadFDkMAIpUIlCLcwPbA8Nzxr7+poaTZuH17fc4VC4ePz0+NZAQBjRCrLlBu1jQ2N2Ky9/Xb7mHaVuExmjJf8XSp/HLsNR/XwkYYAZISVlzFEGCIIeJfkekw+ptNp08wXTPM9+5Z5zRjGvXPBKVKxvFwqAhLckithGKlUKpcrbv6WyT49P9/XIhNMJcpO9ve02Lke1/W4HtNiqqpFTiKwytrfHREo4jHiEQQQAlhqEI+qPex3YIQxwkQqilRklJVirfJf4Urj/weHiof9AAFfO9zamuL1+mWvX5YVWVb8vs11JVAjX25mF659MopYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d4951f8979d7f14631d0f59b3f80b75/e4706/ISpy_advancedHDCam_10.avif 230w", "/en/static/3d4951f8979d7f14631d0f59b3f80b75/d1af7/ISpy_advancedHDCam_10.avif 460w", "/en/static/3d4951f8979d7f14631d0f59b3f80b75/7f308/ISpy_advancedHDCam_10.avif 920w", "/en/static/3d4951f8979d7f14631d0f59b3f80b75/433d4/ISpy_advancedHDCam_10.avif 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d4951f8979d7f14631d0f59b3f80b75/a0b58/ISpy_advancedHDCam_10.webp 230w", "/en/static/3d4951f8979d7f14631d0f59b3f80b75/bc10c/ISpy_advancedHDCam_10.webp 460w", "/en/static/3d4951f8979d7f14631d0f59b3f80b75/966d8/ISpy_advancedHDCam_10.webp 920w", "/en/static/3d4951f8979d7f14631d0f59b3f80b75/27033/ISpy_advancedHDCam_10.webp 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d4951f8979d7f14631d0f59b3f80b75/81c8e/ISpy_advancedHDCam_10.png 230w", "/en/static/3d4951f8979d7f14631d0f59b3f80b75/08a84/ISpy_advancedHDCam_10.png 460w", "/en/static/3d4951f8979d7f14631d0f59b3f80b75/c0255/ISpy_advancedHDCam_10.png 920w", "/en/static/3d4951f8979d7f14631d0f59b3f80b75/72372/ISpy_advancedHDCam_10.png 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d4951f8979d7f14631d0f59b3f80b75/c0255/ISpy_advancedHDCam_10.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The PTZ Menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/4dba2/ISpy_advancedHDCam_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaElEQVQoz2PonTxv0ozls+atnbP40NpNZ5av2blq494DZ+4ePn//yIUHh87e277//JY9p7fuPYOKTu/YvJvBxsTM1czUXEPF09JkVlVKVoBjSXL0+fOX1q5edfnS1fsP365cumzm1GlzZ86aM2MmAs2cOXvWXIZcX51JySrzso3n51sF2ii5mGi1pYV1tLUV5+d0trXevfNk9YrVM6bNmDt77pxZc1DRbIYJFeELG0Jb07zUFGQVlUxdrRzas+Nnz5wdHhoye+bM3z/+rFyxauZ0rJrnMCQmpYdFxgcER4SGhcdExwQGBGdl5xzcf2jZkmWnTpw6e/rcogWLZ82YhakTpHn2zFmzQX6YNXvm7Nlgctb0WTOmzZg/d/6MaTOmTZk2e+ZsrDpBmrGKQhw5d/ZcrK4loJlINFQ1Q0KFPMQwY+r0GdMQaPrUacQjhnXrNq1Zu2ntmo1r125au3bTxvVbNm/aTiQCAJMP9kqQJCwkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/e4706/ISpy_advancedHDCam_11.avif 230w", "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/d1af7/ISpy_advancedHDCam_11.avif 460w", "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/7f308/ISpy_advancedHDCam_11.avif 920w", "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/3c69e/ISpy_advancedHDCam_11.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/a0b58/ISpy_advancedHDCam_11.webp 230w", "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/bc10c/ISpy_advancedHDCam_11.webp 460w", "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/966d8/ISpy_advancedHDCam_11.webp 920w", "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/ee998/ISpy_advancedHDCam_11.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/81c8e/ISpy_advancedHDCam_11.png 230w", "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/08a84/ISpy_advancedHDCam_11.png 460w", "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/c0255/ISpy_advancedHDCam_11.png 920w", "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/4dba2/ISpy_advancedHDCam_11.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05cf6d517dba4a4b7ec329a3b7cee3ef/c0255/ISpy_advancedHDCam_11.png",
              "alt": "Add a INSTAR Camera to iSpy Connect",
              "title": "Add a INSTAR Camera to iSpy Connect",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The PTZ Controller.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      